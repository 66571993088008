<template>
  <div style="width: 100%; position: relative">
    <div class="common-gaine">
      <div class="common-plate-title">
        <span>{{ $trans('修改手机号', '修改手机号') }}</span>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
      <div class="setting-input-box flex-bet-cen">
        <input
          type="text"
          :placeholder="$trans('请输入原始手机号码', '请输入原始手机号码')"
          v-model="person.phone"
        />
      </div>
      <div class="setting-input-box flex-bet-cen">
        <input
          type="text"
          :placeholder="
            $trans('请输入要绑定的手机号码', '请输入要绑定的手机号码')
          "
          v-model="newTelephone"
        />
      </div>
      <div class="setting-input-box flex-bet-cen">
        <input
          type="text"
          :maxlength="6"
          :placeholder="$trans('请输入验证码', '请输入验证码')"
          v-model="verify"
        />
        <div
          class="verify-box font-regular"
          :class="isPostVerify ? 'active' : ''"
          @click="verifyHandle"
        >
          {{ verifyText }}
        </div>
      </div>
      <div class="btn-fill">{{ $trans('保存', '保存') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      verifyText: '获取验证码',
      isPostVerify: false,
      verify: '',
      newTelephone: '',
      person: {},
    }
  },
  mounted() {
    this.person = { ...this.$route.query.data }
  },
  methods: {
    // 获取验证码
    verifyHandle() {
      if (!this.isPostVerify) {
        this.verifyText = 60
        this.isPostVerify = true
        this.countDown()
      }
      return
    },
    // 倒计时
    countDown() {
      if (this.verifyText > 0) {
        this.verifyText--
        setTimeout(this.countDown, 1000)
      } else {
        this.isPostVerify = false
        this.verifyText = '获取验证码'
        return false
      }
    },
  },
}
</script>
<style scoped>
.common-plate-title {
  margin-bottom: 100px;
}
.setting-input-box {
  height: 64px;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  margin-bottom: 20px;
}
.setting-input-box input {
  flex: 1;
  outline: none;
  border: none;
  margin-right: 20px;
  background: transparent;
}
.verify-box {
  height: 33px;
  line-height: 33px;
  background: #444444;
  border-radius: 4px;
  color: #ffffff;
  /* padding: 0 20px; */
  width: 130px;
  text-align: center;
  cursor: pointer;
}
.verify-box.active {
  background: #dddddd;
}
.agree-policy-box input {
  margin-right: 10px;
}
.btn-fill {
  cursor: pointer;
  width: 300px;
  margin: 63px auto 0;
}
</style>
